
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Multiselect from '@vueform/multiselect'

import FormAdd from "@/components/1crm/sales/sales-area/add-sales-area.vue";

export default defineComponent({
  name: "Sales Area",
  components: {
    ErrorMessage,
    Field,
    Form,
    Multiselect,
    FormAdd,
  },

  setup() {

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_USER);
      setCurrentPageBreadcrumbs("Sales Area", ["Data"]);
    });

  },
});
