
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import Multiselect from "@vueform/multiselect";

interface tambah {
  name: string;
  members: any;
  regions: any;
  note: string;
  salesLeadersSelect:any;
}

interface items {
  area: any;
  user_sales_area: any;
  user_sales_area2: any;
  provinsiOptions: any;
  errorName: string;
  errorMember: string;
  errorRegion: string;
  errorLeader: string;
  regions: string;
  members: any;
  salesLeaders: any;
  disableButton: boolean;
}

export default defineComponent({
  name: "Sales Area",
  components: {
    ErrorMessage,
    Field,
    Form,
    Multiselect,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();

    const tambah = reactive<tambah>({
      name: "",
      members: [],
      regions: [],
      note: "",
      salesLeadersSelect:"",
    });

    let items = reactive<items>({
      area: [],
      user_sales_area: [],
      user_sales_area2: [],
      provinsiOptions: [],
      errorName: "",
      errorMember: "",
      errorRegion: "",
      errorLeader: "",
      regions: "",
      members: "",
      salesLeaders:[],
      disableButton: false,
    });

    const saveChanges1 = () => {
      if (
        tambah.name === "" ||
        !tambah.members.length ||
        !tambah.regions.length ||
        tambah.salesLeadersSelect === ""
      ) {
        if(tambah.name === "") {items.errorName = "Tim Area Wajib Diisi";} else {items.errorName =""}
        if(!tambah.members.length) {items.errorMember = "Sales Area Wajib Diisi";} else {items.errorMember =""}
        if(!tambah.regions.length) {items.errorRegion = "Provinsi Wajib Diisi";} else {items.errorRegion =""}
        if(tambah.salesLeadersSelect === "") {items.errorLeader = "Sales Leader Wajib Diisi";} else {items.errorLeader =""}
      } else {
        items.errorName = "";
        items.errorMember = "",
        items.errorRegion = "",
        items.errorLeader = "",

        items.disableButton = true;

          var valSalesLeadersName= "";
          for(var i = 0; i < items.salesLeaders.length; i++){
              if( items.salesLeaders[i]._id == tambah.salesLeadersSelect){
                valSalesLeadersName= items.salesLeaders[i].name
              }
          }

        const datareq = {
          "name": tambah.name,
          "leaderId": tambah.salesLeadersSelect,
          "leaderName": valSalesLeadersName,
          "members": tambah.members,
          "regions":tambah.regions,
          "note": tambah.note
        };

        ApiService.postWithData("crmv2/main_user/sales/team", datareq)
          .then((res) => {
            toast.success("Berhasil Tambah Sales Area");
            router.push({ path: "/superadmin/sales/sales-area/" });
          })
          .catch((e) => {
            items.disableButton = false;
            toast.error(e.response.data.detail);
          });
      }
    };

    const getProvinsi = () => {
      ApiService.getWithoutSlug(
        "https://api.katalis.info/wilayah/getAllProvinces"
      ).then((response) => {
        items.provinsiOptions = response.data;
      });
    };

    const getSalesLeader = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_user/user/combo_user?userRole=SALES_LEADER"
      ).then((response) => {
        items.salesLeaders = response.data;
      });
    };

    const submitAddProvince = () => {
      if (items.regions == "") {
        toast.error("Silahkan Pilih Provinsi Terlebih Dahulu");
      } else {
        if (tambah.regions.length) {
          let hasil = tambah.regions.find((element) => element == items.regions);
          if (hasil) {
            toast.error(hasil + " Sudah Digunakan");
          } else {
            tambah.regions.push(items.regions);
          }
        } else {
          tambah.regions.push(items.regions);
        }
      }
    };

    const submitAddSales = () => {
      if (items.members.name == "") {
        toast.error("Silahkan Pilih Sales Terlebih Dahulu");
      } else {
        	var check = true;
          var saveAdd = false;

          var valMember= {};
          for(var i = 0; i < items.user_sales_area.length; i++){
              if( items.user_sales_area[i].name == items.members){
                valMember= {
                  salesId : items.user_sales_area[i]._id,
                  salesName : items.user_sales_area[i].name,
                }
              }
          }
          
          if (tambah.members.length) {

            for(var i = 0; i < tambah.members.length; i++){
                if( tambah.members[i].salesName == items.members){
                  toast.error(tambah.members[i].salesName + " Sudah Digunakan");
                  check = false;
                  saveAdd = false;
                }
            }

            if (check == true) {
              tambah.members.push(valMember);
              saveAdd = true;
            }
          } else {
            tambah.members.push(valMember);
            saveAdd = true;
          }
          
      }
      console.log(tambah.members)
    };

    const handleDelete = (index, item) => {
      tambah.regions.splice(tambah.regions.indexOf(item), 1);
    };

    const handleDelete2 = (item) => {
      tambah.members.splice(tambah.members.indexOf(item), 1);
    };

    const combo_area = () => {
      ApiService.getWithoutSlug("crmv2/main_user/sales/region/get_combo").then(
        (response) => {
          items.area = response.data;
        }
      );
    };

    const combo_user_sales_area = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_user/user/combo_user?userRole=SALES_AREA"
      ).then((response) => {
        items.user_sales_area = response.data;
      });
    };

    function addSalesModal(){
      items.members = "";
    };

    function addAreaModal(){
      items.regions = "";
    };

    onMounted(() => {
      store.dispatch(Actions.VERIFY_AUTH_USER);
      combo_area();
      combo_user_sales_area();
      getProvinsi();
      getSalesLeader();
    });

    return {
      submitButton,
      saveChanges1,
      tambah,
      items,
      combo_area,
      combo_user_sales_area,
      getProvinsi,
      getSalesLeader,
      submitAddProvince,
      submitAddSales,
      handleDelete,
      handleDelete2,

      addSalesModal,
      addAreaModal,
    };
  },
});
